/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Contact Section
--------------------------------*/
#contact {
    padding: 85px 0 30px 0;
}

#contact .contact-info {
    margin-bottom: 20px;
    text-align: center;
}

#contact .contact-info i {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 27px;
    color: #000247;
    border-radius: 33.33%;
    padding: 8px;
    border: 2px dotted #166ab5;
}

#contact .contact-info address,
#contact .contact-info p {
    margin-bottom: 0;
    color: #000;

}

#contact .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #999;
}

#contact .contact-info a {
    color: #000;
    text-decoration: none !important;
}

#contact .contact-info a:hover,
#contact .contact-info address:hover {
    color: #166ab5;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
    margin-bottom: 20px;
}

@media (min-width: 768px) {

    #contact .contact-address,
    #contact .contact-phone,
    #contact .contact-email {
        padding: 20px 0;
    }
}

@media (min-width: 768px) {
    #contact .contact-phone {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

#contact #google-map {
    height: 290px;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    #contact #google-map {
        margin-top: 20px;
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contact-address address {
    cursor: pointer;
}