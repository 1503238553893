/*--------------------------------------------------------------
# Products Section
--------------------------------------------------------------*/
#mosquitoNetDoors,
#mosquitonet,
#curtains,
#blinds,
#flooringmat,
#wallpapers,
#RodsAndBrackets {
    padding: 40px 0;
}

#Products .box {
    padding: 30px;
    box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
    background: #fff;
    transition: 0.4s;
    height: 100%;
    border: 1px solid #d5e1ed;
}

#Products .box:hover {
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    background: #623b93;
    border-color: #623b93;
    color: #fff;
}

#Products .box .icon {
    float: left;
}

#Products .box .icon i {
    color: #444;
    font-size: 64px;
    transition: 0.5s;
    line-height: 0;
    margin-top: 34px;
}

#Products .box .icon i:before {
    background: #0c2e8a;
    background: linear-gradient(45deg, #50d8af 0%, #a3ebd5 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#Products .box h4 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
    cursor: pointer;
}

#Products .box h4 a {
    color: #000247;
}

#Products .box p {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 0;
    line-height: 24px;
}

#Products .product-items {
    padding: 40px 0;
    overflow: hidden;
}

@media (max-width: 767px) {
    #Products .box .box {
        margin-bottom: 20px;
    }

    #Products .box .icon {
        float: none;
        text-align: center;
        padding-bottom: 15px;
    }

    #Products .box h4,
    #Products .box p {
        margin-left: 0;
        text-align: center;
    }
}

/*--------------------------------------------------------------
# Products Items
--------------------------------------------------------------*/
.product-items .nav-tabs {
    border: 0;
}

.product-items .nav-link {
    border: 1px solid #b5ccdb;
    padding: 15px;
    transition: 0.3s;
    color: #15222b;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-items .nav-link i {
    padding-right: 15px;
    font-size: 48px;
}

.product-items .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.product-items .nav-link:hover {
    color: #ff005d;
}

.product-items .nav-link.active {
    background: #ff005d;
    color: #fff;
    border-color: #ff005d;
}

@media (max-width: 768px) {
    .product-items .nav-link i {
        padding: 0;
        line-height: 1;
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .product-items .nav-link {
        padding: 15px;
    }

    .product-items .nav-link i {
        font-size: 24px;
    }
}

.product-items .tab-content {
    margin-top: 30px;
}

.product-items .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
}

.product-items .tab-pane ul {
    list-style: none;
    padding: 0;
}

.product-items .tab-pane ul li {
    padding-bottom: 10px;
}

.product-items .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #ff005d;
}

.product-items .tab-pane p:last-child {
    margin-bottom: 0;
}

.product-items h2 {
    font-size: 32px;
    color: #000247;
    text-transform: none;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
}

.product-items h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #000247;
    bottom: 0;
    left: 0;
}

.product-items .tab-pane span {
    font-weight: bold;
}