/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
    padding: 0px 0;
    overflow: hidden;
}

/* Sections Header
  --------------------------------*/
.section-header {
    margin-bottom: 30px;
}

.section-header h2 {
    font-size: 32px;
    color: #000247;
    text-transform: none;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
}

.section-header h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #000247;
    bottom: 0;
    left: 0;
}

.section-header p {
    padding: 0;
    margin: 0;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
    padding: 20px 0;
    background-color: #fafafa;
    min-height: 40px;
}

.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
}

@media (max-width: 992px) {
    .breadcrumbs h2 {
        margin: 0 0 10px 0;
    }
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}