/* Our Product Section
--------------------------------*/
#product {
    background: #fff;
    padding: 85px 0 30px 0;
}

#product #product-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
}

#product #product-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 18px 12px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #444;
    margin: 0 3px 10px 3px;
    transition: all ease-in-out 0.3s;
    background: #f7f7f7;
    border-radius: 4px;
}

#product #product-flters li:hover,
#product #product-flters li.filter-active {
    color: #fff;
    background: #50d8af;
}

#product #product-flters li:last-child {
    margin-right: 0;
}

#product .product-item {
    margin-bottom: 30px;
    overflow: hidden;
}

#product .product-item img {
    position: relative;
    top: 0;
    transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#product .product-item .product-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -50px;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: #000247;
    padding: 15px 20px;
}

#product .product-item .product-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

#product .product-item .product-info p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
}

#product .product-item .product-info .preview-link,
#product .product-item .product-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: rgba(255, 255, 255, 0.6);
    transition: ease-in-out 0.3s;
}

#product .product-item .product-info .preview-link:hover,
#product .product-item .product-info .details-link:hover {
    color: #fff;
}

#product .product-item .product-info .details-link {
    right: 15px;
}

#product .product-item:hover img {
    top: -30px;
}

#product .product-item:hover .product-info {
    opacity: 1;
    bottom: 0;
}

/*--------------------------------------------------------------
  # product Details
  --------------------------------------------------------------*/
.product-details {
    padding-top: 40px;
}

.product-details .product-details-slider img {
    width: 100%;
}

.product-details .product-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.product-details .product-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #50d8af;
}

.product-details .product-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #50d8af;
}

.product-details .product-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(12, 46, 138, 0.08);
}

.product-details .product-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.product-details .product-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.product-details .product-info ul li+li {
    margin-top: 10px;
}

.product-details .product-description {
    padding-top: 30px;
}

.product-details .product-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.product-details .product-description p {
    padding: 0;
}

.product-container .row1 {
    position: relative;
    height: 891px;
}

.product-item .pi1 {
    position: absolute;
    left: 0px;
    top: 0px;
}

.product-item .pi2 {
    position: absolute;
    left: 380px;
    top: 0px;
}

.product-item .pi3 {
    position: absolute;
    left: 760px;
    top: 0px;
}

.product-item .pi4 {
    position: absolute;
    left: 0px;
    top: 297px;
}

.product-item .pi5 {
    position: absolute;
    left: 380px;
    top: 297px;
}

.product-item .pi6 {
    position: absolute;
    left: 760px;
    top: 297px;
}

.product-item .pi7 {
    position: absolute;
    left: 0px;
    top: 594px;
}

.product-item .pi8 {
    position: absolute;
    left: 380px;
    top: 594px;
}

.product-item .pi9 {
    position: absolute;
    left: 760px;
    top: 594px;
}