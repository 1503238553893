.carousel-inner img {
    opacity: 0.4;
}

.carousel-item h1 {
    text-align: unset;
}

.carousel-inner h1 {
    color: #0d0e44;
    font-size: 43px;
    font-weight: 700;
}

.carousel-inner .overlay {
    inset: 0;
    position: absolute;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(158, 152, 156, 0.3);
    /* center overlay text */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}