#footer {
    background: #f2f5f8;
    padding: 20px 0px;
    font-size: 14px;
    text-align: center;
}

.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #000247;
    width: 40px;
    height: 40px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #166ab5;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Footer Bar
  --------------------------------------------------------------*/
#footerbar {
    background: #e5e5ec;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    padding: 0;
    height: 100px;
}

#footerbar a {
    text-decoration: none;
}


#footerbar a h1 {
    color: #000247;
    text-decoration: none;
}

#footerbar .contact-info i {
    font-style: normal;
    color: #50d8af;
}

#footerbar .contact-info i a,
#footerbar .contact-info i span {
    padding-left: 5px;
    color: #444;
}

#footerbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
}

#footerbar .contact-info i a:hover {
    color: #50d8af;
}

#footerbar .social-links a {
    color: #555;
    padding: 0 15px;
    display: inline-block;
    line-height: 1px;
    border-left: 1px solid #e9e9e9;
}

#footerbar .social-links a:hover {
    color: #50d8af;
}

#footerbar .social-links a:first-child {
    border-left: 0;
}