/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
#services {
    padding: 85px 0 30px 0;
}

.services .service-box {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    height: 100%;
    padding: 60px 30px;
    text-align: center;
    transition: 0.3s;
    border-radius: 5px;
}

.services .service-box .icon {
    font-size: 36px;
    padding: 40px 20px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    line-height: 0;
    transition: 0.3s;
}

.services .service-box h3 {
    color: #000247;
    font-weight: 600;
}

.services .service-box .read-more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 20px;
}

.services .service-box .read-more i {
    line-height: 0;
    margin-left: 5px;
    font-size: 18px;
}

.services .service-box.blue {
    border-bottom: 3px solid #2db6fa;
}

.services .service-box.blue .icon {
    color: #2db6fa;
    background: #dbf3fe;
}

.services .service-box.blue .read-more {
    color: #2db6fa;
}

.services .service-box.blue:hover {
    background: #2db6fa;
}

.services .service-box.orange {
    border-bottom: 3px solid #f68c09;
}

.services .service-box.orange .icon {
    color: #f68c09;
    background: #fde3c4;
}

.services .service-box.orange .read-more {
    color: #f68c09;
}

.services .service-box.orange:hover {
    background: #f68c09;
}

.services .service-box.green {
    border-bottom: 3px solid #08da4e;
}

.services .service-box.green .icon {
    color: #08da4e;
    background: #cffddf;
}

.services .service-box.green .read-more {
    color: #08da4e;
}

.services .service-box.green:hover {
    background: #08da4e;
}

.services .service-box.red {
    border-bottom: 3px solid #e9222c;
}

.services .service-box.red .icon {
    color: #e9222c;
    background: #fef7f8;
}

.services .service-box.red .read-more {
    color: #e9222c;
}

.services .service-box.red:hover {
    background: #e9222c;
}

.services .service-box.purple {
    border-bottom: 3px solid #b50edf;
}

.services .service-box.purple .icon {
    color: #b50edf;
    background: #f8e4fd;
}

.services .service-box.purple .read-more {
    color: #b50edf;
}

.services .service-box.purple:hover {
    background: #b50edf;
}

.services .service-box.pink {
    border-bottom: 3px solid #f51f9c;
}

.services .service-box.pink .icon {
    color: #f51f9c;
    background: #feecf7;
}

.services .service-box.pink .read-more {
    color: #f51f9c;
}

.services .service-box.pink:hover {
    background: #f51f9c;
}

.services .service-box:hover h3,
.services .service-box:hover p,
.services .service-box:hover .read-more {
    color: #fff;
}

.services .service-box:hover .icon {
    background: #fff;
}